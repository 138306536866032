$rootPath: "/v40/23179";
// Override variables in the default theme
$brand-primary: #313131;
$brand-info: #313131;

@import "Theme";

.btn-primary {
    background-color: #f37a28;

    &:active, &:hover, &:focus {
        background-color: darken(#f37a28, 10%);
        border-color: darken(#f37a28, 12%);
    }
}
